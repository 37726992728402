import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { Link } from 'gatsby-plugin-intl';

import ArrowRight from '../assets/images/svg/arrow-right.svg';

const ListElementWrapper = ({ children, link }) => {
  if (link) {
    return (
      <Link
        className="block py-4"
        to={link}
      >
        {children}
      </Link>
    )
  }

  return (
    <div className="py-4">
      {children}
    </div>
  )
}

const ServiceDetailsList = ({ items, parentSlug }) => {
  const intl = useIntl();
  const [activeItem, setActiveItem] = useState(null);

  return (
    <ul className="border-t">
      {items.map((item, index) => (
        <li 
          key={index}
          className="border-b"
          onMouseEnter={() => setActiveItem(index)}
          onMouseLeave={() => setActiveItem(null)}
        >
          <ListElementWrapper link={item.slug?.[intl.locale]?.current && item.poster && item.title?.[intl.locale] && item._rawBody?.[intl.locale] ? `/services/${parentSlug}/${item.slug[intl.locale].current}#jumpmark` : null}>
            <div className="relative flex items-center justify-between">
              <span className={`font-sans transition-opacity duration-300 ${activeItem !== null && activeItem !== index ? 'opacity-50' : ''}`}>
                {item.title[intl.locale]}
              </span>

              {item.slug?.[intl.locale]?.current && item.poster && item.title?.[intl.locale] && item._rawBody?.[intl.locale] && (
                <span className="absolute right-0 top-1/2 transform -translate-y-1/2">
                  <span className={`hidden medium:block mr-7 tro-button-primary transition-opacity duration-300 ${activeItem !== index ? 'opacity-0' : ''}`}>
                    {intl.formatMessage({ id: "learnMore" })}
                  </span>
                  <ArrowRight className="right-0 block medium:absolute bottom-2" />
                </span>
              )}
            </div>

            {item.subtitle?.[intl.locale] && (
              <p className="mt-3 font-sans text-sm text-brand-dark">
                {item.subtitle?.[intl.locale]}
              </p>
            )}
          </ListElementWrapper>
        </li>
      ))}
    </ul>
  )
};

export default ServiceDetailsList;
