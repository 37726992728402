import React from "react";
import { useIntl, Link } from "gatsby-plugin-intl";
import Diamond from "../assets/images/svg/diamond.svg";

const Submenu = ({ item, isSubpage }) => {
  if (typeof window === "undefined") return null;

  const intl = useIntl();

  const subpageItems = () => {
    const { details } = item.singlepage;

    const items = details.map((item) => {
      if (!(item.slug?.[intl.locale]?.current && item.poster && item.title?.[intl.locale] && item._rawBody?.[intl.locale])) return null;

      return {
        title: item.title[intl.locale],
        slug: item.slug[intl.locale].current,
      };
    }).filter((item) => item !== null);

    if (items.length === 0) return null;

    return items;
  };

  if (!subpageItems()) return null;

  const listContainsActiveItem = subpageItems()?.some((subpageItem) => {
    const path = `/${intl.locale}/services/${item.singlepage.slug[intl.locale].current}/${subpageItem.slug}`;

    return window.location.pathname.startsWith(path);
  });

  const isActiveSubpage = (subpageItem) => window.location.pathname.startsWith(`/${intl.locale}/services/${item.singlepage.slug[intl.locale].current}/${subpageItem.slug}`);

  return (
    <ul
      className={`overflow-hidden ${!listContainsActiveItem ? 'hidden max-h-0 group-hover:max-h-48 transition-all' : ''} tablet:block tablet:mt-1 tablet:ml-4.5`} 
      style={{ transitionDuration: `${subpageItems().length * 250}ms` }}
    >
      {subpageItems().map((subpageItem, index) => (
        <li key={index}>
          <Link
            className={`text-xs ${isActiveSubpage(subpageItem) ? '' : 'opacity-60 hover:opacity-100 transition-opacity'}`}
            to={`/services/${item.singlepage.slug[intl.locale].current}/${subpageItem.slug}#jumpmark`}
            aria-current={isActiveSubpage(subpageItem) ? 'page' : null}
          >
            {subpageItem.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};

const SidebarService = ({ className, entries, isSubpage }) => {
  const intl = useIntl();

  return (
    <ul className={`${className} ${isSubpage ? 'tro-service-subpage-sidebar-nav' : 'space-x-4 tablet:space-x-0'} tablet:overflow-x-hidden tablet:px-0 tablet:block tablet:space-y-6 tablet:text-sm px-4 flex overflow-x-scroll font-mono text-xs uppercase`}>
      {entries.map((item, index) => (
        <li className="flex group gap-4 tablet:block" key={index}>
          <Link
            className="flex items-center tablet:px-4.5 tro-service-sidebar-nav-item"
            to={`/services/${item.singlepage?.slug?.[intl.locale]?.current || ''}`}
          >
            <Diamond className="hidden mr-2 w-2.5 h-2.5" />
            <span
              className="whitespace-nowrap medium:whitespace-normal border-b-3 border-brand-transparent group-hover:border-brand group-hover:border-b-3 group-hover:text-gray-700 transition-all duration-300 ease-out"
            >
              {item.title[intl.locale]}
            </span>
          </Link>

          {item.singlepage?.details && (
            <Submenu 
              item={item} 
              isSubpage={isSubpage}
            />
          )}
        </li>
      ))}
    </ul>
  );
};

export default SidebarService;
