import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import Layout from '../../layouts/default-layout';

import PageHead from '../pageHead';
import SidebarService from "../sidebarService";
import BodyBlockContent from '../bodyBlockContent';
import ServiceDetailsList from '../serviceDetailsList';
import MoreProjects from '../moreProjects';

import { extractImageFromData } from '../../lib/helpers';

const ServicesSlugPage = ({ path, pageContext }) => {
  const intl = useIntl()
  const content = pageContext.data
  const pageDetails = content.singlepage
  const title = content.title ? content.title[intl.locale] : '';
  const metaTitle = pageDetails.meta_title ? pageDetails.meta_title[intl.locale] : title;
  const metaDescription = pageDetails.meta_description ? pageDetails.meta_description[intl.locale] : content.subtitle ? content.subtitle[intl.locale] : null;

  const [showMoreProjects, setShowMoreProjects] = useState(true);

  return (
    <Layout textWhite={pageDetails.lightHeader} title={metaTitle} description={metaDescription} noIndex={content.meta_noindex} path={path} slugs={pageDetails.slug}>
      <PageHead
        headline={title}
        subline={content.subtitle && content.subtitle[intl.locale]}
        backgroundImage={extractImageFromData(content, 'poster')}
        textWhite={pageDetails.lightHeader}
      />

      <div className="relative">
        <div id="jumpmark" className="w-full pb-12 pointer-events-none tablet:absolute tro-default-padding-x large:pb-0">
          <div className="tablet:w-1/4">
            <SidebarService
              className="-mx-4 pointer-events-auto tablet:mx-0 tablet:sticky tablet:top-12"
              entries={pageContext.allServices}
            />
          </div>
        </div>

        <BodyBlockContent data={pageDetails._rawBody[intl.locale]} />
      </div>

      {pageDetails.details?.length > 0 || showMoreProjects ? (
        <div className="pb-10 medium:pb-24 tro-default-padding-x">
          <div className="tro-grid-responsive space-y-8 medium:space-y-0 medium:gap-y-14 large:gap-y-20">
            {pageDetails.details?.length > 0 ? (
              <div className="medium:col-start-4 col-span-full medium:col-span-8 tablet:col-start-4 tablet:col-span-6">
                <ServiceDetailsList
                  items={pageDetails.details}
                  parentSlug={pageDetails.slug[intl.locale].current}
                />
              </div>
            ) : null}

            {showMoreProjects ? (
              <div className="overflow-visible col-start-1 col-span-full medium:col-start-4 medium:col-span-9 medium:pl-0">
                <h2 className="text-sm small:text-lg medium:text-xl">
                  {intl.formatMessage({ id: "projects" })}
                </h2>

                <div className="mt-4 -mx-4 medium:ml-0 medium:-mr-8">
                  <MoreProjects cases={pageContext.cases} tag={content.tag} noEntriesFound={() => setShowMoreProjects(false)} />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </Layout>
  );
};

export default ServicesSlugPage;
