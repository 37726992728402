import React, { useRef, useState } from "react";
import { useIntl, navigate } from "gatsby-plugin-intl";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Mousewheel } from "swiper";

import ArrowRight from "../assets/images/svg/arrow-right.svg";
import ArrowRightShort from "../assets/images/svg/arrow-right-short.svg";
import TeaserCase from "./teaserCase";

SwiperCore.use([Mousewheel]);

const MoreProjects = ({ cases = null, tag = null, noEntriesFound }) => {
  if (!tag?.key || !cases || cases.length === 0) {
    noEntriesFound();
    return (<></>)
  }

  const tagsCases = cases.filter((item) => item.tags?.some((t) => t.key === tag.key));

  const intl = useIntl()
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const swiperSettings = {
    autoHeight: false,
    grabCursor: true,
    mousewheel: {
      forceToAxis: true,
    },
    spaceBetween: 30,
    slidesPerView: 1.4,
    slidesOffsetBefore: 22,
    width: 400,

    breakpoints: {
      // these corresponds to the values in breakpoints in _variables
      649: {
        slidesPerView: 2.2,
        width: null,
      },
      768: {
        slidesOffsetBefore: 0,
      },
      1400: {
        slidesPerView: 3.2,
        width: null,
      },
    },

    onSlideChange: function () {
      setActiveIndex(this.activeIndex);
    },
  }

  function showProjects (e) {
    e.preventDefault();
    navigate('/projects', { state: { selected: tag.key } })
  }

  return (
    <>
      <div className="relative">
        <button
          className={`absolute z-10 flex items-center justify-center right-4 top-1/2 transform -translate-y-1/2 bg-brand w-7 h-7 -rotate-45 transition-opacity group ${activeIndex === swiperRef.current?.swiper.slides.length - 1 ? 'opacity-0 pointer-events-none' : ''}`}
          onClick={() => swiperRef.current.swiper.slideNext()}
        >
          <ArrowRightShort className="transform rotate-45 group-hover:translate-x-0.5 group-hover:translate-y-0.5 transition-transform duration-300" />
        </button>

        <Swiper ref={swiperRef} {...swiperSettings}>
          {tagsCases.map((item, index) => (
            <SwiperSlide 
              key={index}
              className={""}
              >
                <TeaserCase item={item} hideTags />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="flex justify-end w-full pr-4 mt-8 medium:pr-8">
        <a
          href=""
          onClick={showProjects}
          className="relative"
        >
          <span className="mr-7 tro-button-primary">
            {intl.formatMessage({ id: "moreProjects" })}
          </span>
          <ArrowRight className="absolute right-0 block bottom-1" />
        </a>
      </div>
    </>
  );
};

export default MoreProjects;
